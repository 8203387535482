<template>
  <base-page-layout title="Airflow DAGs">
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field v-model="search" class="pt-0 mt-0" width="200" hide-details single-line="" placeholder="Busca" append-icon="search" />
        <v-btn color="primary" style="margin-left: 40px" @click="clearDagDialog = true">Clear Dags</v-btn>
        <v-spacer />
        <div class="pt-0 mt-0" width="200">
          <v-select v-model="selectedStat" :items="dagStats" label="Status" />
        </div>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table :headers="headers" :loading="$apollo.queries.listDagErrors.loading" :items="listDagErrors" :search="search">
          <template v-slot:item="{ item }">
            <tr style="cursor: pointer;" @click="openDagDetails(item)">
              <td>{{ item.dag_id }}</td>
              <td>{{ item.task_id }}</td>
              <td>{{ dateFormat(item.execution_date) }}</td>
              <td class="text-xs-center">
                <v-icon v-if="item.state !== 'success'" color="error">error</v-icon>
                <v-icon v-else color="success">check_circle</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <dag-details :dialog="dialog" :dag="selectedDag" @close="closeDagDetails" />
    <clear-dags :dialog="clearDagDialog" :listDagErrors.sync="listDagErrors" @close="closeClearDags" />
  </base-page-layout>
</template>

<script>
import { QUERY_GET_AIRFLOW_DAGS } from '@/modules/airflow/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    DagDetails: () => import('@/modules/airflow/DagDetails.vue'),
    ClearDags: () => import('@/modules/airflow/components/ClearDags.vue')
  },
  data: () => ({
    dialog: false,
    clearDagDialog: false,
    search: '',
    dagStats: ['Success', 'Failed'],
    selectedStat: '',
    selectedDag: {}
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Dag',
          value: 'dag_id',
          width: '20%'
        },
        {
          text: 'Pipeline Task',
          value: 'task_id',
          width: '10%'
        },
        {
          text: 'Data/Hora de Execução',
          value: 'execution_date',
          width: '10%'
        },
        {
          text: 'State',
          value: 'state',
          width: '10%'
        }
      ]
    }
  },
  apollo: {
    listDagErrors: {
      query: QUERY_GET_AIRFLOW_DAGS,
      variables() {
        if (!this.selectedStat) return { state: 'failed' }
        return { state: this.selectedStat.toLowerCase() }
      },
      update({ listDagErrors }) {
        return listDagErrors
      }
    }
  },
  methods: {
    openDagDetails(dag) {
      this.selectedDag = dag
      this.dialog = true
    },
    closeDagDetails() {
      this.selectedDag = {}
      this.dialog = false
    },
    closeClearDags() {
      this.clearDagDialog = false
    },
    dateFormat(date) {
      if (!date) return '-'
      return this.$moment(date).format('YYYY-MM-DD, HH:mm:ss')
    }
  }
}
</script>
