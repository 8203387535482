import gql from 'graphql-tag'

export const QUERY_GET_AIRFLOW_DAGS = gql`
  query($state: String!) {
    listDagErrors(state: $state) {
      dag_id
      duration
      end_date
      execution_date
      executor_config
      hostname
      max_tries
      operator
      pid
      pool
      pool_slots
      priority_weight
      queue
      queued_when
      sla_miss {
        dag_id
        description
        email_sent
        execution_date
        notification_sent
        task_id
        timestamp
      }
      start_date
      state
      task_id
      try_number
      unixname
      link
    }
  }
`
export const MUTATION_MASSIVE_CLEAR_DAGS = gql`
  mutation($id: [JSONObject]) {
    massiveTaskClear(dagIds: $id) {
      msg
      data
      error
    }
  }
`
